<script>
import {relatorioTermoAlienacao} from "@/domain/leiloes/services"
import RelatorioTemplate from "./RelatorioTemplate"

export default {
  mixins: [RelatorioTemplate],
  nome: 'TermoAlienacao',
  created() {
    this.isLoading = true
    relatorioTermoAlienacao(this.leilao.id)
        .then((response) => {
          this.dados = response.data
          this.isLoading = false
        })
        .catch((response) => {
          // this.isLoading = false
          this.alertApiError(response)
          console.log(response)
        })
  }
}
</script>
